import React, { forwardRef, InputHTMLAttributes, useRef } from 'react';

import ReactIs from 'react-is';

import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { useI18nTranslation } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { Box } from '../Box/Box';
import { InputBaseProps, inputStyle } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

type InputDateAutocompleteAttribute = 'bday';

type InputDateFieldHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'onBlur' | 'onChange' | 'onFocus' | 'placeholder' | 'value' | 'defaultValue'
>;

/**
 * Note: using `isInvalidDate` might not be the best solution. We'd rather have yup (or other outside) validation
 * handle the message, and inputDate validation as a whole. I couldn't properly get it working with yup. If you
 * can, please update it!
 */
interface InputDateProps extends Omit<InputBaseProps, 'isReadOnly'> {
  // Custom autocomplete
  autoComplete?: InputDateAutocompleteAttribute;
  isInvalidDate?: boolean;
  describedBy?: string;
  forceError?: boolean;
}

const InputDateComponent = forwardRef<HTMLFieldSetElement, React.PropsWithChildren<InputDateProps>>(
  ({ autoComplete, children, error, hint, isDisabled, isInvalidDate, isOptional, label, name }, ref) => {
    if (process.env.NODE_ENV === 'development') {
      // Check to make sure that at least three children are passed
      if (React.Children.count(children) < 3) {
        // eslint-disable-next-line no-console
        console.error(
          'Error: The `<InputDate>` component is missing one of the required `children`. Make sure `<InputDate.Day />`, `<InputDate.Month />` and `<InputDate.Year />` are all included.',
        );
      }
    }

    const localRef = useRef<HTMLFieldSetElement>(null);
    // Merge the local ref and forwarded ref so we can forward it *and* use it locally
    const mergedRef = mergeRefs([ref, localRef]);

    const i18nOptionalText = useI18nTranslation('optional');
    const { errorId, hintId, describedBy } = useInputIds({ error, hint }); // could turn useInputIds into error: string | string[]
    useValidationErrorEvent({ error, name }, localRef);

    const augmentedChildren = React.Children.map(children, child => {
      if (ReactIs.isElement(child)) {
        if (process.env.NODE_ENV === 'development') {
          // Check to make sure that the children are the right components. When they are not React logs an error because
          // it tries to pass the `autoComplete` and `describedBy` props.
          if (child.type !== Day && child.type !== Month && child.type !== Year) {
            // eslint-disable-next-line no-console
            console.error(
              'Error: The `<InputDate>` component contains `children` that are invalid. `<InputDate>` may only contain `<InputDate.Day />`, `<InputDate.Month />` or `<InputDate.Year />`.',
            );
          }
        }

        // Augment children by passing 'autoComplete' and `describedBy` property.
        return React.cloneElement(child as React.ReactElement<InputDateProps>, {
          autoComplete,
          describedBy: errorId,
          forceError: isInvalidDate,
        });
      }
    });

    return (
      <fieldset ref={mergedRef} aria-describedby={describedBy} disabled={isDisabled}>
        <legend>
          <Box paddingBottom="2">
            <Text size="BodyS" weight="bold">
              {label}
              {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
            </Text>
          </Box>
        </legend>

        <Stack gap="2">
          <Stack.Item grow>
            <Stack direction="row" gap="3">
              {augmentedChildren}
            </Stack>
          </Stack.Item>

          {error ? (
            <Error id={errorId} hasArrow={false}>
              {error}
            </Error>
          ) : null}

          {hint ? <Hint id={hintId}>{hint}</Hint> : null}
        </Stack>
      </fieldset>
    );
  },
);
interface InputDateInputProps
  extends Pick<InputBaseProps, 'label' | 'name' | 'isReadOnly'>,
    InputDateFieldHTMLAttributes {
  field: 'day' | 'month' | 'year';
  hasError?: boolean;

  // Props added by InputDate
  describedBy?: never;
  autoComplete?: never;
  forceError?: never;
}

const StyledInput = styled('input', inputStyle);

const InputDateInput = forwardRef<HTMLInputElement, InputDateInputProps>(
  ({ field, label, autoComplete, hasError, describedBy, forceError, isReadOnly, ...rest }, ref) => {
    const { inputId } = useInputIds({});

    return (
      <Stack.Item grow>
        <Stack gap="2">
          <label htmlFor={inputId}>
            <Text size="BodyS">{label}</Text>
          </label>
          <Stack.Item>
            <StyledInput
              ref={ref}
              id={inputId}
              type="text"
              inputMode="numeric"
              aria-describedby={hasError || forceError ? describedBy : undefined}
              aria-invalid={hasError || forceError || undefined}
              isInvalid={hasError || forceError || undefined}
              autoComplete={autoComplete ? `${autoComplete}-${field}` : undefined}
              readOnly={isReadOnly}
              {...rest}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    );
  },
);

/**
 * InputDate.Day compound component to ensure day input has the right props
 */
const Day = forwardRef<HTMLInputElement, Omit<InputDateInputProps, 'field'>>((props, ref) => (
  <InputDateInput field="day" {...props} ref={ref} />
));

/**
 * InputDate.Month compound component to ensure month input has the right props
 */
const Month = forwardRef<HTMLInputElement, Omit<InputDateInputProps, 'field'>>((props, ref) => (
  <InputDateInput field="month" {...props} ref={ref} />
));

/**
 * InputDate.Year compound component to ensure year input has the right props
 */
const Year = forwardRef<HTMLInputElement, Omit<InputDateInputProps, 'field'>>((props, ref) => (
  <InputDateInput field="year" {...props} ref={ref} />
));

StyledInput.displayName = 'styled(input)';

// Compound components with forwardRef cause issues:
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
export const InputDate = Object.assign({}, InputDateComponent, { Day, Month, Year });
