import React, { createContext, FC, useContext } from 'react';

import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import ReactIs from 'react-is';

import { getCombinedLinkFieldValueHref } from '@common/link';

type HrefOrLinkValue =
  | { href: string; linkValue?: never }
  | { href?: never; linkValue: Pick<LinkFieldValue, 'anchor' | 'href' | 'querystring' | 'target'> };

type LinkProps = {
  className?: never;
  currentMatch?: 'exact';
  editable?: string;
  linkType: string;
  target?: string;
} & HrefOrLinkValue;

type LinkComponent = FC<React.PropsWithChildren<LinkProps>>;

type ProviderProps = {
  component: LinkComponent;
};

const Link: LinkComponent = ({ children, className, href: _href, linkValue }) => {
  const { target } = linkValue || {};
  const href = _href ?? getCombinedLinkFieldValueHref(linkValue);

  return ReactIs.isElement(children) ? (
    React.cloneElement(children as React.ReactElement<LinkProps>, { href, className, target })
  ) : (
    <a href={href} className={className} target={target}>
      {children}
    </a>
  );
};

const LinkContext = createContext<LinkComponent>(Link);

export const useLinkComponent = () => useContext(LinkContext);

export const LinkComponentProvider: FC<React.PropsWithChildren<ProviderProps>> = ({ children, component }) => (
  <LinkContext.Provider value={component}>{children}</LinkContext.Provider>
);
