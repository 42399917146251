import React, { FC, ReactElement } from 'react';

import logger from '@common/log';
import { useLayoutData } from '@sitecore/common';

import { useRouter, useRouterPath, RouterContext } from './useRouter';
import { isMatch } from './util';

export interface RouteProps {
  route: string;
  component: FC;
}

type RouteComponent = ReactElement<RouteProps>;

const normalize = (path: string): string => path.replace(/\/+$/, '');

const isRouteComponent = (component: ReactElement): component is ReactElement<RouteProps> => {
  if (component.props && typeof component.props === 'object') {
    return 'route' in component.props;
  }
  return false;
};
const getRouteComponents = (components: React.ReactNode): RouteComponent[] =>
  React.Children.toArray(components).filter(React.isValidElement).filter(isRouteComponent);

export type ErrorPaths = 'notfound' | 'error';
export const errorPaths = { notfound: '404-not-found', error: '500-error' };

export const RouterProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const { activePath } = useRouter();
  const {
    context: { basePath = '/' },
    route: { fields = {} },
  } = useLayoutData();

  const isAllowFrontEndRouting = fields.allowFrontEndRouting?.value === true;

  if (!isAllowFrontEndRouting) {
    logger.error('mPPGjA', 'Router is only allowed with "allowFrontEndRouting" enabled in Sitecore', {});
  }

  const routerBasePath = String(fields.frontEndRootPath?.value ?? basePath);

  const activeRoute = activePath ? normalize(activePath).replace(normalize(routerBasePath), '') + '/' : null;

  return <RouterContext.Provider value={{ routerBasePath, activeRoute }}>{children}</RouterContext.Provider>;
};

export const Routes: FC<React.PropsWithChildren> = ({ children }) => {
  const { routerBasePath, activeRoute } = useRouterPath();

  if (!activeRoute) return null;

  const routeComponents = getRouteComponents(children);
  const route = routeComponents.find(child => isMatch(child.props.route, activeRoute)) ?? null;

  if (!route) {
    logger.error('O4w0az', `No match for route "${activeRoute}" in "${routerBasePath}"`);
    return null;
  }

  const match = isMatch(route.props.route, activeRoute);
  const params = match ? match.params : {};

  return React.createElement(route.props.component, params);
};

export const Router: FC<React.PropsWithChildren> = ({ children }) => (
  <RouterProvider>
    <Routes>{children}</Routes>
  </RouterProvider>
);

export const Route = ({ route, component }: RouteProps) => null;
