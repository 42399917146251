import React from 'react';

import { Checkbox } from './Checkbox';
import { CheckTile } from './CheckTile/CheckTile';
import { useInputIds } from '../../hooks/useInputIds';
import { useI18nTranslation } from '../../providers/i18n';
import { InputBaseProps } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

interface CheckboxLikeProps {
  error?: string;
  hint?: string;
}

export const CheckboxGroup: React.FC<React.PropsWithChildren<CheckboxGroupProps>> = ({
  children,
  label,
  error,
  hint,
  isOptional,
}) => {
  const { errorId, hintId } = useInputIds({ error, hint });

  const augmentedChildren = React.Children.map(children, child => {
    if (process.env.NODE_ENV === 'development') {
      if (React.isValidElement(child)) {
        if (child.type !== Checkbox && child.type !== CheckTile) {
          // eslint-disable-next-line no-console
          console.error(
            'Error: The `<CheckboxGroup>` component contains `children` that are invalid. `<CheckboxGroup>` may only contain `<Checkbox /> or <CheckTile />`.',
          );
        }
        const childProps = child.props as CheckboxLikeProps;
        if (childProps.error || childProps.hint) {
          // eslint-disable-next-line no-console
          console.error(
            'Error: The `<CheckboxGroup>` component contains `<Checkbox />` that have an error or hint set, please set these props on the `<CheckboxGroup>` instead.',
          );
        }
      }
    }

    return <div>{child}</div>;
  });
  const i18nOptionalText = useI18nTranslation('optional');

  return (
    <fieldset>
      <Stack gap="3">
        {label && (
          <Text as="legend" weight="bold" size="BodyS">
            {label} {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
          </Text>
        )}
        <Stack gap="3">{augmentedChildren}</Stack>
        {error && (
          <Error id={errorId} hasArrow={false}>
            {error}
          </Error>
        )}

        {hint && <Hint id={hintId}>{hint}</Hint>}
      </Stack>
    </fieldset>
  );
};

type CheckboxGroupProps = Omit<InputBaseProps, 'name' | 'isDisabled' | 'isReadOnly'> & {
  children: React.ReactNode[];
};
