import React from 'react';

import { DayPicker as ReactDayPicker } from 'react-day-picker';

import { DayPickerProps } from './Datepicker';
import { styled } from '../../stitches.config';

export const DayPicker: React.FC<DayPickerProps> = styled(ReactDayPicker, {
  '.rdp-month_grid': {
    borderSpacing: '0 $space$2',
    padding: '$6',
    width: '100%',
  },

  '.rdp-day:not(.rdp-hidden)': {
    border: '$borderWidths$s solid transparent',
    borderRadius: '$buttonBorderRadiusDefault',
    color: '$textPrimary',
    cursor: 'pointer',
    fontSize: '$BodyS',
    fontWeight: '$bodyBold',
    padding: 0,

    '&.rdp-today': {
      border: '$borderWidths$m solid $borderDividerLowEmphasis',

      '&:not(:hover):not(.rdp-selected) .rdp-day_button': {
        // necessary for keeping the grid layout from jumping
        margin: 'calc(($borderWidths$m - $borderWidths$s) * -1)',
      },

      '&.rdp-selected': {
        border: '$borderWidths$s solid transparent',
      },

      '&.rdp-focused': {
        border: '$borderWidths$s solid $borderDividerMediumEmphasis',
      },
    },

    '&.rdp-selected': {
      backgroundColor: '$backgroundDark',
      color: '$textInverted',

      '&.rdp-focused': {
        borderColor: '$neutralWhite',
        outline: '$borderWidths$m solid $colors$backgroundDark',
      },
    },

    '&.rdp-range_start:not(.rdp-range_end)': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },

    '&.rdp-range_end:not(.rdp-range_start)': {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },

    '&.rdp-range_middle': {
      backgroundColor: '$backgroundSecondary',
      color: '$textPrimary',

      '&:not(:nth-child(1 of :not(.rdp-hidden)))': {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
      },

      '&:not(:nth-last-child(1 of :not(.rdp-hidden)))': {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      },
    },

    '&.rdp-focused': {
      border: '$borderWidths$s solid $borderDividerMediumEmphasis',
      borderRadius: '$buttonBorderRadiusDefault',
      outline: '$borderWidths$m solid $colors$backgroundDark',
    },

    '&.rdp-disabled': {
      color: '$controlsInactive',

      '&:hover': {
        border: '$borderWidths$s solid transparent',
        cursor: 'default',
      },
    },

    '&:hover': {
      border: '$borderWidths$s solid $borderDividerMediumEmphasis',
    },
  },
  variants: {
    pagedNavigation: {
      true: {
        '.rdp-months': {
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          '@md': {
            flexDirection: 'row',
          },
          '.rdp-month': {
            width: '100%',
            '@md': {
              width: '50%',
            },
          },
        },
      },
    },
  },
});

export const DayButton = styled('button', {
  background: 'transparent',
  border: '0 solid transparent',
  color: 'inherit',
  cursor: 'inherit',
  fontWeight: 'inherit',
  padding: '$2',
  width: '100%',

  '&:focus-visible': {
    outline: 'none',
  },
});
